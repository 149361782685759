import React from 'react';
import Clients from './Clients';

const ClientsPage: React.FC = () => {
    return (
        <>
            <Clients />
        </>
    );
};

export default ClientsPage;