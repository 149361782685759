import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  Button,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  ListItemText,
} from '@mui/material';
import {
  updateProject,
  updateBankOffer,
  updateAmenity,
  updateFloorPlan,
  updateLocationHighlight,
} from '../../../api/services';
import { Project, LocationHighlight, FloorPlan, Amenity, BankOffer } from '../ProjectInterface';
import CustomInput from '../../../Components/Inputs/CustomInput';
import FileUploadContainer from '../../../Components/FileUploadContainer';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-router-dom';
import { ArrowDropDown } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

interface EditProjectProps {
  open: boolean;
  onClose: () => void;
  selectedItem: LocationHighlight | FloorPlan | Amenity | BankOffer | Project;
  editType: string;
  projectId: string;
  refetch: () => void;
}


const BHK_OPTIONS = [
  { value: 1, label: '1 BHK' },
  { value: 2, label: '2 BHK' },
  { value: 3, label: '3 BHK' },
  { value: 3.5, label: '3.5 BHK' },
  { value: 4, label: '4 BHK' },
  { value: 4.5, label: '4.5 BHK' },
  { value: 5, label: '5 BHK' },
];

const EditProject: React.FC<EditProjectProps> = ({ open, onClose, selectedItem, editType, projectId, refetch }) => {
  const { control, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});

  const [imageUploaded, setImageUploaded] = useState(false);



  const handleImageDeletefloorplan = () => {
    setFormData({ ...formData, floorImage: null });
    setImageUploaded(false);
  }
  
  const handleImageDeletebankoffer = () => {
    setFormData({ ...formData, bankIcon: null });
    setImageUploaded(false);
  }


  useEffect(() => {
    setFormData(selectedItem);
  }, [selectedItem]);

  const handleSave = async () => {
    setLoading(true);
    try {
      switch (editType) {
        case 'mainDetails':
          await updateProject(projectId, formData);
          toast.success("Project updated successfully");
          break;
        case 'locationHighlight':
          await updateLocationHighlight(projectId, formData._id, formData);
          toast.success("Location Highlight updated successfully");
          break;
        case 'floorPlan':
          await updateFloorPlan(projectId, formData._id, formData);
          toast.success("Floor Plan updated successfully");
          break;
        case 'amenity':
          await updateAmenity(projectId, formData._id, formData);
          toast.success("Amenity updated successfully");
          break;
        case 'bankOffer':
          await updateBankOffer(projectId, formData._id, formData);
          toast.success("Bank Offer updated successfully");
          break;
        
        case 'otherDetails':
          await updateProject(projectId, formData);
          toast.success("Project updated successfully");
          break;


        default:
          throw new Error("Unknown edit type");
      }
      refetch();
      onClose();
    } catch (error) {
      toast.error("Error updating project section");
      console.error("Error updating project section", error);
    } finally {
      setLoading(false);
    }
  };

  const renderEditFields = () => {
    switch (editType) {
      case 'mainDetails':
        return (
          <>
            {/* //name  */}

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="name">Name</InputLabel>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <CustomInput
                      placeholder="Name"
                      fullWidth
                      value={formData?.name || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, name: e.target.value })}
                  />
                )}
              />
            </FormControl>

            {/* //description  */}
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="description">Description</InputLabel>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="Description"
                  fullWidth
                  multiline
                  rows={5}
                  value={formData?.description || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, description: e.target.value })}
                />
                )}
              />
            </FormControl>


            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="establishedYear">Established Year</InputLabel>
              <Controller
                name="establishedYear"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Established Year"
                    fullWidth
                    value={formData?.establishedYear || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, establishedYear: e.target.value })}
                  />
                )}
              />
            </FormControl>


            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="sftPrice">SFT Price</InputLabel>
              <Controller
                name="sftPrice"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="SFT Price"
                  fullWidth
                  type="number"
                  value={formData?.sftPrice || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, sftPrice: e.target.value })}
                />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="builderName">Builder Name</InputLabel>
              <Controller
                name="builderName"
                control={control}
                render={({ field }) => (
                <CustomInput
                  placeholder="Builder Name"
                  fullWidth
                  value={formData?.builderName || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, builderName: e.target.value })}
                />
                )}
              />
            </FormControl>
            
          </>
        );

        
        
        case "Address":
            return (
                <>
                  <FormControl fullWidth sx={{ marginBottom: 3 }}>
                  <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="Location">Location</InputLabel>
                  <Controller
                    name="Location"
                    control={control}
                    render={({ field }) => (
                    <CustomInput
                      placeholder="Location"
                      fullWidth
                      value={formData?.location || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, location: e.target.value })}
                    />
                    )}
                  />
                </FormControl>
                


                <FormControl fullWidth sx={{ marginBottom: 3 }}>
                  <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="City">City</InputLabel>
                  <Controller
                    name="City"
                    control={control}
                    render={({ field }) => (

                    <CustomInput
                      placeholder="City"
                      fullWidth
                      value={formData?.city || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, city: e.target.value })}
                    />
                    )}
                  />
                </FormControl>




                <FormControl fullWidth sx={{ marginBottom: 3 }}>
                  <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="Zone">Zone</InputLabel>
                  <Controller
                    name="Zone"
                    control={control}
                    render={({ field }) => (
                    <CustomInput
                      placeholder="Zone"
                      fullWidth
                      value={formData?.zone || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, zone: e.target.value })}
                    />
                    )}
                  />
                  </FormControl>




                  <FormControl fullWidth sx={{ marginBottom: 3 }}>
                    <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="street">Street</InputLabel>
                      <Controller
                        name="street"
                        control={control}
                        render={({ field }) => (
                          <CustomInput
                            placeholder="street"
                            fullWidth
                            value={formData?.street || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, street: e.target.value })}
                          />
                        )}
                      />
                  </FormControl>


                  <FormControl fullWidth sx={{ marginBottom: 3 }}>
                    <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="pincode">Pincode</InputLabel>
                    <Controller
                      name="pincode"
                      control={control}
                      render={({ field }) => (
                        <CustomInput
                          placeholder="Pincode"
                          fullWidth
                          value={formData?.pincode || ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, pincode: e.target.value })}
                        />
                      )}
                    />
                  </FormControl>



                </>
            )


      case 'locationHighlight':
        return (
          <>

          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="LocationType">Location Type</InputLabel>
              <Controller
                name="LocationType"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Location Type"
                    fullWidth
                    value={formData?.locationType || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, locationType: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="LocationName">Location Name</InputLabel>
              <Controller
                name="LocationName"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Location Name"
                    fullWidth
                    value={formData?.locationName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, locationName: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="Time">Time (min)</InputLabel>
              <Controller
                name="Time"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Time (min)"
                    fullWidth
                    type="number"
                    value={formData?.time || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, time: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="Distance">Distance (km)</InputLabel>
              <Controller
                name="Distance"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Distance (km)"
                    fullWidth
                    type="number"
                    value={formData?.distance || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, distance: e.target.value })}
                  />
                )}
              />
            </FormControl>

          </>
        );

      case 'floorPlan':
        return (
          <>
           <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="FloorNumber">Floor Number</InputLabel>
              <Controller
                name="FloorNumber"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Floor Number"
                    fullWidth
                    type="number"
                    value={formData?.floorNumber || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, floorNumber: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="Size">Size (sqft)</InputLabel>
              <Controller
                name="Size"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Size (sqft)"
                    fullWidth
                    type="number"
                    value={formData?.size || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, size: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="FullPrice">Full Price</InputLabel>
              <Controller
                name="FullPrice"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Full Price"
                    fullWidth
                    type="number"
                    value={formData?.fullPrice || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, fullPrice: e.target.value })}
                  />
                )}
              />
            </FormControl>

            {/* <CustomInput
              placeholder="EMI Price"
              fullWidth
              type="number"
              value={formData?.emiPrice || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, emiPrice: e.target.value })}
            /> */}


            <FileUploadContainer
              onFileSelect={(url) => {
                setFormData({ ...formData, floorImage: url || null });
                setImageUploaded(!!url);
              }}
              foldername="projectImages"
              existingImage={formData?.floorImage || ''}
              onDelete={handleImageDeletefloorplan}
            />
          </>
        );

      case 'amenity':
        return (
          <>
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="AmenityName">Amenity Name</InputLabel>
              <Controller
                name="AmenityName"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Amenity Name"
                    fullWidth
                    value={formData?.name || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, name: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="IconName">Icon Name</InputLabel>
              <Controller
                name="IconName"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Icon Name"
                    fullWidth
                    value={formData?.iconName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, iconName: e.target.value })}
                  />
                )}
              />
            </FormControl>

          </>
        );

      case 'bankOffer':
        return (
          <>
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="BankName">Bank Name</InputLabel>
              <Controller
                name="BankName"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Bank Name"
                    fullWidth
                    value={formData.bankName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, bankName: e.target.value })}
                  />
                )}
              />
            </FormControl>

            <FileUploadContainer
              onFileSelect={(url) => {
                setFormData({ ...formData, bankIcon: url || null });
                setImageUploaded(!!url);
              }}
              foldername="projectImages"
              existingImage={formData?.bankIcon || ''}
              onDelete={handleImageDeletebankoffer}
            />
          </>
        );


      case 'otherDetails':
        return (
          <>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="minSize">minSize</InputLabel>
              <Controller
                name="minSize"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="Min Size"
                  fullWidth
                  type="number"
                  value={formData?.minSize || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, minSize: e.target.value })}
    
                />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="maxSize">maxSize</InputLabel>
              <Controller
                name="maxSize"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="Max Size"
                  fullWidth
                  type="number"
                  value={formData?.maxSize || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, maxSize: e.target.value })}
    
                />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="Buildings">Buildings</InputLabel>
              <Controller
                name="Buildings"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Buildings"
                    fullWidth
                    type="number"
                    value={formData?.buildings || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, buildings: e.target.value })}
                  />
                )}
              />
            </FormControl>

            {/* parking area */}
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="parkingArea">Parking Area</InputLabel>
              <Controller
                name="parkingArea"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Parking Area"
                    fullWidth
                    type="number"
                    value={formData?.parkingArea || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, parkingArea: e.target.value })}
                  />
                )}
              />
            </FormControl>

            {/* launchDate */}
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="launchDate">Launch Date</InputLabel>
              <Controller
                name="launchDate"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Launch Date"
                    fullWidth
                    value={formData?.launchDate || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, launchDate: e.target.value })}
                  />
                )}
              />
            </FormControl>


            {/* locationIframe */}
            <FormControl fullWidth sx={{ marginBottom: 5 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="locationIframe">Location Iframe</InputLabel>
              <Controller
                name="locationIframe"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    placeholder="Location Iframe"
                    fullWidth
                    value={formData?.locationIframe || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, locationIframe: e.target.value })}
                  />
                )}
              />
            </FormControl>
  
              {/* propertyType */}  

            <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <InputLabel shrink sx={{ ml: -1, mt:-2 }} htmlFor="builder-property-type-input">
              Property Type
            </InputLabel>
            <Controller
              name="propertyType"
              control={control}
              rules={{ required: 'Property Type is required' }}
              render={({ field }) => (
                <Select
                  id="builder-property-type-input"
                      
                  {...field}
                  onChange={(e) => setFormData({ ...formData, propertyType: e.target.value })}
                  error={!!errors.propertyType}
                  IconComponent={ArrowDropDown}
                  renderValue={() =>
                    formData?.propertyType ? (
                      <Box display="flex" alignItems="center">
                        {formData?.propertyType}
                      </Box>
                    ) : (
                      'Property Types'
                    )
                  }
                  style={{
                    width: "100%",
                    height: "45px",
                    border: "1px solid #1212121A",
                    borderRadius: "10px",
                    opacity: 0.6,
                    boxShadow: "0px 6px 14px #36408D08",
                    fontSize: "14px",
                    color: "#1D1D1D",
                    textAlign: 'left',
                  }}
                  inputProps={{
                    style: {
                      fontFamily: "Mundial, sans-serif",
                      fontSize: "14px",
                    },
                  }}
                  SelectDisplayProps={{
                    style: {
                      fontFamily: "Mundial, sans-serif",
                      fontSize: "14px",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select property type...
                  </MenuItem>
                  <MenuItem value="Apartments">Apartments</MenuItem>
                  <MenuItem value="Villas">Villas</MenuItem>
                  <MenuItem value="Plots">Plots</MenuItem>
                  <MenuItem value="Commercial">Commercial</MenuItem>
                  <MenuItem value="Ultra Luxury">Ultra Luxury</MenuItem>
                </Select>
              )}
            />
          </FormControl>

            
            {/* videoLink */}
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="videoLink">Video Link</InputLabel>
              <Controller
                name="videoLink"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="Video Link"
                  fullWidth
                  value={formData?.videoLink || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, videoLink: e.target.value })}
                />
                )}
              />
            </FormControl>


            {/* dimensions */}
            <FormControl fullWidth sx={{ marginBottom: 4 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="dimensions">Dimensions</InputLabel>
              <Controller
                name="dimensions"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="Dimensions"
                  fullWidth
                  value={formData?.dimensions || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, dimensions: e.target.value })}
                />
                )}
              />
            </FormControl>

            {/* projectBHK  with multiple select options with checkboxes, by default select the items if any are there in that projectBHK */}
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4, mt: -1.5 }} htmlFor="projectBHK">BHK</InputLabel>
              <Controller
                name="projectBHK"
                control={control}
                render={({ field }) => (
                  <Select
                    id="projectBHK"
                    multiple
                    value={formData?.projectBHK || []}
                    onChange={(e) => setFormData({ ...formData, projectBHK: e.target.value })}
                    renderValue={(selected) => selected.join(', ')}
                    IconComponent={ArrowDropDown}
                    style={{
                      width: "100%",
                      height: "45px",
                      border: "1px solid #1212121A",
                      borderRadius: "10px",
                      opacity: 0.6,
                      boxShadow: "0px 6px 14px #36408D08",
                      fontSize: "14px",
                      color: "#1D1D1D",
                      textAlign: 'left',
                    }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                  >
                    {BHK_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={formData?.projectBHK?.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {/* reraId */}

            <FormControl fullWidth sx={{ marginBottom: 5 }}>
              <InputLabel shrink sx={{ ml: -1.4 }} htmlFor="reraId">RERA ID</InputLabel>
              <Controller
                name="reraId"
                control={control}
                render={({ field }) => (
                  <CustomInput
                  placeholder="RERA ID"
                  fullWidth
                  value={formData?.reraId || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, reraId: e.target.value })}
                />
                )}
              />
            </FormControl>

            {/* status will be a select option with single select with options as : Active, Inactive, Completed, On Hold, Cancelled , if already any value is there in status then select that value by default */}
            <FormControl fullWidth sx={{ marginBottom: 3 }}>
              <InputLabel shrink sx={{ ml: -1.4, mt:-1.5 }} htmlFor="status">Status</InputLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    id="status"
                    value={formData?.status || ''}
                    onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                    IconComponent={ArrowDropDown}
                    style={{
                      width: "100%",
                      height: "45px",
                      border: "1px solid #1212121A",
                      borderRadius: "10px",
                      opacity: 0.6,
                      boxShadow: "0px 6px 14px #36408D08",
                      fontSize: "14px",
                      color: "#1D1D1D",
                      textAlign: 'left',
                    }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select status...
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="On Hold">On Hold</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                )}
              />
            </FormControl>




            

          </>
        );

      default:
        return null;
    }
  };

  return (

    <Drawer
    anchor="right"
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        width: '100%',
        maxWidth: '360px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
      },
    }}
  >
    {/* Header */}
    <Box sx={{
      width: '100%',
      height: '60px',
      backgroundColor: 'primary.main',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
    }}>
      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 550 }}>
          Edit {editType.charAt(0).toUpperCase() + editType.slice(1)}
      </Typography>
      <IconButton onClick={onClose} sx={{ color: 'white', marginRight: 3 }}>
        <CloseIcon />
      </IconButton>
    </Box>

    {/* Content */}
    <Box sx={{ padding: '20px' }}>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {renderEditFields()}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ mt: 3 }}
              fullWidth
            >
              Update
            </Button>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default EditProject;
