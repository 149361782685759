import React, { useState } from 'react';
import {
  Box, TextField, IconButton, InputAdornment, Button, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Typography
} from '@mui/material';
import { Search as SearchIcon, Add as Plus, Download as DownloadIcon  } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteChannelPartner, deleteUser } from '../../api/services';
import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as XLSX from "xlsx";


interface User {
  _id: string;
  name: string;
  email: string;
  type: string;
  phoneNumber: string;
  userimage?: string;
  message?: string;
}

const rowsPerPage = 50;

interface VamsiramProps {
  VamsiramData: User[];
  onDelete: () => void;
}

const Vamsiram: React.FC<VamsiramProps> = ({ VamsiramData, onDelete }) => {
  const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };


  const handleDownloadLeads = () => {
    const filteredData = VamsiramData.map((user, index) => [
      index + 1,
      user.name,
      user.email,
      user.phoneNumber,
      user.type,
      user.message || "N/A"
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["S. No", "Name", "Email", "Phone Number", "Type", "Message"],
      ...filteredData
    ]);

    // Define styles
    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" } }, // White text
      fill: { fgColor: { rgb: "1F4E78" } }, // Dark Blue background
      alignment: { horizontal: "center", vertical: "center" }
    };

    const dataStyle = {
      alignment: { horizontal: "center", vertical: "center" }
    };

    // Apply header styles
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"] as string);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = headerStyle;
    }

    // Apply data styles
    for (let R = 1; R <= filteredData.length; ++R) {
      for (let C = 0; C < 6; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = dataStyle;
        }
      }
    }

    // Auto adjust column widths
    worksheet["!cols"] = [
      { wch: 5 },  // S.No
      { wch: 20 }, // Name
      { wch: 30 }, // Email
      { wch: 15 }, // Phone Number
      { wch: 15 }, // Type
      { wch: 40 }  // Message
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
    XLSX.writeFile(workbook, "Vamsiram_Leads.xlsx");
  };


  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
  };

  const filteredVamsiram = VamsiramData.filter(user =>
    user.name.toLowerCase().includes(searchText.toLowerCase()) ||
    user.email.toLowerCase().includes(searchText.toLowerCase())
  );

  const totalPages = Math.ceil(filteredVamsiram.length / rowsPerPage);
  const paginatedRows = filteredVamsiram.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <TextField
          placeholder="Search for Vamsiram..."
          variant="outlined"
          size="small"
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ 
            width: { xs: '100%', sm: '350px' },
            backgroundColor: '#fff',
            boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.1)',
            height: '36px',
            borderRadius: '8px', // Added border radius as per the design
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-input': {
              padding: '7px',
              marginLeft: '7px',
            },
            '& .MuiInputLabel-outlined': {
              transform: 'translate(14px, 12px) scale(0.5)',
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '& .MuiInputAdornment-positionEnd': {
              marginRight: '10px',
            },
            '& .MuiSvgIcon-root': {
              color: '#30779d',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },          
          }}
        />
           <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
          sx={{ mt: 1, marginLeft: "10px" }}
          onClick={handleDownloadLeads}
        >
          Download Leads
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ border: '1px solid #1212121A', borderRadius: '10px', opacity: 1 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#30779d40' }}>
            <TableRow>
              <TableCell align="left" sx={{ padding: "8px" }}>
                <Typography variant="caption" sx={{ marginLeft: "22px", fontWeight: '600' }}>S.No</Typography>
              </TableCell>
              <TableCell align="left" sx={{ padding: "8px" }}>
                <Typography variant="caption" sx={{ marginLeft: "22px", fontWeight: '600' }}>Name</Typography>
              </TableCell>
              <TableCell align="left" sx={{ padding: "8px" }}>
                <Typography variant="caption" sx={{ marginLeft: "22px", fontWeight: '600' }}>Email Address</Typography>
              </TableCell>
              <TableCell align="left" sx={{ padding: "8px" }}>
                <Typography variant="caption" sx={{ marginLeft: "22px", fontWeight: '600' }}>Phone Number</Typography>
              </TableCell>
              <TableCell align="left" sx={{ padding: "8px" }}>
                <Typography variant="caption" sx={{ marginLeft: "22px", fontWeight: '600' }}>User Type</Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row: User, index: number) => (
              <TableRow key={row._id}>
                <TableCell sx={{ padding: '5px' }}>
                  <Typography variant="caption" sx={{ marginLeft: "25px" }}>{(currentPage - 1) * rowsPerPage + index + 1}</Typography>
                </TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  <Typography variant="caption" sx={{ marginLeft: "25px" }}>{row.name}</Typography>
                </TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  <Typography variant="caption" sx={{ marginLeft: "25px" }}>{row.email}</Typography>
                </TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  <Typography variant="caption" sx={{ marginLeft: "25px" }}>{row.phoneNumber}</Typography>
                </TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  <Typography variant="caption" sx={{ marginLeft: "25px" }}>{row.type}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <ImageGalleryComponent /> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              color: theme.palette.primary.main,
              height: "22px",
              minWidth: "22px",
              fontSize: "14px",
              fontWeight: "bold",
              margin: "0 4px",
              backgroundColor: "#EFEFEF",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            "& .MuiPaginationItem-page.Mui-selected:hover": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            "& .Mui-selected": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Vamsiram;
