import React from 'react';
import NoVamsiram from './NoVamsiram';
import Vamsiram from './Vamsiram';
import { useQuery } from 'react-query';
import { getVamsiramLeads, getChannelPartners } from '../../api/services'; // Add API call for channel partners
import { LinearProgress } from '@mui/material';

const VamsiramPage: React.FC = () => {
    // Fetch Vamsiram data
    const { data: VamsiramData, isLoading: isLoadingVamsiram, error: VamsiramError, refetch: refetchVamsiram } = useQuery('getVamsiram', getVamsiramLeads);

    const combinedData = [
        ...(VamsiramData?.data || []), 
    ];


    if (combinedData.length === 0) {
        return <NoVamsiram />;
    }

    return <Vamsiram VamsiramData={combinedData} onDelete={() => { refetchVamsiram();  }} />;
};

export default VamsiramPage;
