import React from 'react';
import MasterData from './MasterData';

const Dashboard: React.FC = () => {
    return (
        <>
        <MasterData />

        </>
    );
};

export default Dashboard;