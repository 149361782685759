import React from 'react';

const Messages: React.FC = () => {
    return (
        <div>
            <h1>Messages</h1>
            <p>This is the Messages page.</p>
        </div>
    );
};

export default Messages;